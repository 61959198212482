//新增/编辑菜品
const addOrUpdDetailsUrl = `/gateway/hc-health/meal/addOrUpdDetails`;
//获取菜品详情
const getDetailsUrl = `/gateway/hc-health/meal/getDetails`;
//删除套餐菜品详情
const delMealDetailsUrl = `/gateway/hc-health/meal/delMealDetails`;
//获取套餐菜品列表
const getMealDetailsListUrl = `/gateway/hc-health/meal/getMealDetailsList`;
//点餐套餐
const mealListUrl = `/gateway/hc-health/meal/mealList`;
//查询订单
const allOrderListUrl = `/gateway/hc-health/meal/allOrderList`;
//删除套餐-管理后台
const delMealUrl = `/gateway/hc-health/meal/delMeal`;
// 新增/编辑套餐-管理后台
const addMealUrl = `/gateway/hc-health/meal/addMeal`;
//取消订单
const cancelOrderUrl = `/gateway/hc-health/meal/cancelOrder`;
export {
  addOrUpdDetailsUrl,
  getDetailsUrl,
  delMealDetailsUrl,
  getMealDetailsListUrl,
  mealListUrl,
  allOrderListUrl,
  delMealUrl,
  addMealUrl,
  cancelOrderUrl,
};
