<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="back"></v-button>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <div style="display: flex; align-items: center; margin-bottom: 20px">
          <div style="font-size: 14px; margin-right: 10px">时间查询：</div>
          <v-date-picker
            v-model="datetimerange"
            type="datetimerange"
            clearable
            @change="changeDate(1)"
          ></v-date-picker>
        </div>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="toEdit(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      width="30%"
    >
      <el-form label-position="right" label-width="120px">
        <el-form-item label="日期：">
          <v-date-picker
            v-model="form.day"
            type="date"
            placeholder="选择日期"
            @change="changeDate(2)"
          ></v-date-picker>
        </el-form-item>

        <el-form-item label="中餐餐品：">
          <v-input
            placeholder="请输入中餐餐品"
            clearable
            class="filter-item"
            v-model="form.lunch"
          ></v-input>
        </el-form-item>
        <el-form-item label="晚餐餐品：">
          <v-input
            placeholder="请输入晚餐餐品"
            clearable
            class="filter-item"
            v-model="form.dinner"
          ></v-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialogBottomBtn">
        <v-button
          size="medium"
          @click="dialogFormVisible = false"
          text="返回"
        ></v-button>
        <v-button size="medium" @click="submit()" text="保存"></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMealDetailsListUrl,
  addOrUpdDetailsUrl,
  delMealDetailsUrl,
} from "./api.js";
import { Message, MessageBox } from "element-ui";
export default {
  name: "setMenu",
  data() {
    return {
      dialogTitle: "新增",
      dialogFormVisible: false,
      tableUrl: getMealDetailsListUrl,
      searchParam: {
        id: "",
        startTime: null,
        endTime: null,
      },
      form: {
        id: null,
        day: "",
        lunch: null,
        dinner: null,
      },
      datetimerange: [],
      headers: [
        {
          prop: "day",
          label: "日期",
        },
        {
          prop: "lunch",
          label: "中餐",
        },
        {
          prop: "dinner",
          label: "晚餐",
        },
      ],
    };
  },
  created() {
    this.searchParam.id = this.$route.query.id;
    // this.$setBreadList("设置菜品");
  },
  methods: {
    toAdd() {
      this.form.day = "";
      this.form.lunch = "";
      this.form.dinner = "";
      this.dialogFormVisible = true;
      this.dialogTitle = "新增";
    },
    back() {
      this.$router.go(-1);
    },
    toEdit(item) {
      this.dialogFormVisible = true;
      this.dialogTitle = "编辑";
      this.form.day = item.day;
      this.form.id = item.id;
      this.form.lunch = item.lunch;
      this.form.dinner = item.dinner;
    },
    submit() {
      if (this.form.day == "") {
        this.$message.error("请选择日期");
        return;
      }
      if (this.form.lunch == "") {
        this.$message.error("请输入中餐餐品");
        return;
      }
      if (this.form.dinner == "") {
        this.$message.error("请输入晚餐餐品");
        return;
      }
      let params = {
        day: this.form.day,
        lunch: this.form.lunch,
        dinner: this.form.dinner,
        tenantId: this.$store.state.app.userInfo.tenantId,
        inuser: this.$store.state.app.userInfo.userId,
        mealId: this.$route.query.id,
        id: this.dialogTitle == "新增" ? null : this.form.id,
      };
      this.$axios.post(`${addOrUpdDetailsUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.dialogFormVisible = false;
          this.$message.success("操作成功！");
          this.$refs.list.search();
        }
      });
    },
    toDelete(item) {
      MessageBox.confirm("是否确定删除该菜品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: item.id,
          };
          this.$axios
            .post(`${delMealDetailsUrl}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
    changeDate(type) {
      if (type == 1) {
        if (this.datetimerange && this.datetimerange.length == 2) {
          this.searchParam.startTime =
            this.datetimerange[0].format("yyyy-MM-dd");
          this.searchParam.endTime = this.datetimerange[1].format("yyyy-MM-dd");
        } else {
          this.searchParam.startTime = null;
          this.searchParam.endTime = null;
        }
      } else {
        this.form.day = this.form.day.format("yyyy-MM-dd");
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
